import React, { FC, useCallback } from 'react'
import FontelloIcon from '@sport1/news-styleguide/FontelloIcon'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Touchable from '@sport1/news-styleguide/Touchable'
import { useOverlay } from '@/utils/overlay/OverlayProvider'
import { SearchBarProps } from '@/layouts/Standard/Header/components/HeaderBanner/components/Search'
import { useTracking } from '@/utils/tracking/TrackingProvider'

const MobileSearchContainer: FC<SearchBarProps> = props => {
    const { openOverlay } = useOverlay()
    const { trackInteraction } = useTracking()
    const openSearchBar = useCallback(() => {
        openOverlay({ type: 'search', content: props })
        trackInteraction({
            devices: 'mobile',
            interaction_category: 'navigation',
            interaction_action: 'click',
            interaction_label: 'searchbar',
            platforms: 'mobile',
        })
    }, [openOverlay, props, trackInteraction])
    return (
        <NonFlexingContainer alignRight>
            <Touchable onPress={openSearchBar}>
                <FontelloIcon
                    testID="mobile-search-icon"
                    name="search"
                    color="onyx"
                    fontSize={24}
                />
            </Touchable>
        </NonFlexingContainer>
    )
}

export default MobileSearchContainer

import useTheme from '@sport1/react-elements/useTheme'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { LabelProps, TagProps } from '@sport1/types/web'
import { useRouter } from 'next/router'
import React, { FC, useMemo, useRef, useState } from 'react'
import { DisplayProp, ThemeProps } from '@sport1/news-styleguide/Types'
import colorPattern from '@sport1/news-styleguide/colorPattern'
import CardHeadline from '@sport1/news-styleguide/CardHeadline'
import BurgerMenu from '@/components/BurgerMenu'
import CompetitionsTabBar from '@/components/TabBar/CompetitionsTabBar'
import SportsNavBar from '@/components/TabBar/SportsNavBar'
import SubChannelTabBar from '@/components/TabBar/SubChannelTabBar'
import { useAdPlacementContext } from '@/context/AdPlacementContext/AdPlacementProvider'
import useInViewport from '@/hooks/useInViewport'
import BreadCrumbs from '@/layouts/Standard/BreadCrumbs'
import HeaderBanner from '@/layouts/Standard/Header/components/HeaderBanner/HeaderBanner'
import renderAdWithPlaceholder from '@/utils/ads/display/renderAdWithPlaceholders'
import { useNavigation } from '@/utils/navigation/Standard'

type Props = {
    competitions?: (TagProps | LabelProps)[]
    disableBillboard?: boolean // to disable billboard, need to pass 'true', otherwise billboard is enabled
}

const Header: FC<Props> = ({ competitions, disableBillboard = false }) => {
    const router = useRouter()
    const { surfaceColors, themeName } = (useTheme() as ThemeProps['theme']) || {}
    const { navigation } = useNavigation()
    const { firstBillboard } = useAdPlacementContext()
    const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false)
    const [isBurgerMenuOpenSportarten, setIsBurgerMenuOpenSportarten] = useState(false)
    const waypointRef = useRef<HTMLDivElement | null>(null)
    const isInViewPort = useInViewport(waypointRef, true)
    const isSticky = !isInViewPort

    const {
        navigationItems,
        iconUrl: tagIconUrl,
        title: tagTitle,
        href: tagHref,
    } = navigation?.tag || {}
    const tvTagId = 85

    const pageTabbarType = useMemo(() => {
        if (competitions && competitions?.length > 0) {
            return 'competitionsTabbar'
        }
        if (navigationItems && navigationItems?.length > 1) {
            if (
                router.asPath.includes('channel') &&
                Array.isArray(router.query.slug) &&
                router.query.slug.length === 1 &&
                competitions?.length
            ) {
                return 'competitionsTabbar'
            }
            return 'subChannelTabbar'
        }
    }, [competitions, navigationItems, router.query.slug, router.asPath])

    const isCompetitionsTabbar = pageTabbarType === 'competitionsTabbar'
    const isSubChannelTabbar = pageTabbarType === 'subChannelTabbar'

    const getSportsNavBarDisplay = (): DisplayProp['display'] => {
        if (isCompetitionsTabbar) {
            return ['none', 'none', 'none', 'flex']
        }
        if (isSubChannelTabbar) {
            return 'none'
        }
        return 'flex'
    }

    const shouldShowCompetitionsTabbar = useMemo(
        () =>
            isCompetitionsTabbar &&
            (competitions?.length || (navigationItems && navigationItems.length > 1)),
        [competitions, isCompetitionsTabbar, navigationItems]
    )

    return (
        <>
            <NonFlexingContainer
                backgroundColor={surfaceColors?.['background-secondary']}
                testID="Standard-Header-HeaderBanner"
            >
                <HeaderBanner />
            </NonFlexingContainer>
            {isBurgerMenuOpenSportarten && (
                <BurgerMenu
                    isBurgerMenuOpen={isBurgerMenuOpenSportarten}
                    onStateChange={setIsBurgerMenuOpenSportarten}
                    initialTag={navigation?.sportartenTag}
                />
            )}
            {isBurgerMenuOpen && (
                <BurgerMenu
                    isBurgerMenuOpen={isBurgerMenuOpen}
                    onStateChange={setIsBurgerMenuOpen}
                />
            )}

            <div ref={waypointRef} />
            <div className="sticky z-50 top-0 ease-in-out duration-500 transition-shadow">
                {isSubChannelTabbar ? (
                    <SubChannelTabBar
                        key={pageTabbarType}
                        items={navigationItems || []}
                        staticTabIcon={themeName === 'dark' ? undefined : tagIconUrl}
                        staticTabName={themeName === 'dark' ? undefined : tagTitle}
                        staticTabHref={tagHref}
                        backgroundColor={
                            themeName === 'dark' ? colorPattern.sky : colorPattern.onyx
                        }
                        isBurgerMenuVisible={isSticky}
                        setIsBurgerMenuOpen={setIsBurgerMenuOpen}
                    />
                ) : (
                    <NonFlexingContainer
                        display={getSportsNavBarDisplay()}
                        testID="Standard-Header-SportsNavBar"
                    >
                        <SportsNavBar
                            key="sportsNavBar"
                            items={navigation?.sportsNavBarItems || []}
                            pageTag={navigation?.tag}
                            backgroundColor="onyx"
                            isBurgerMenuVisible={isSticky}
                            setIsBurgerMenuOpen={setIsBurgerMenuOpen}
                            setIsBurgerMenuOpenSportarten={setIsBurgerMenuOpenSportarten}
                        />
                    </NonFlexingContainer>
                )}
                {shouldShowCompetitionsTabbar && (
                    <NonFlexingContainer
                        testID="Header-Competition-Navbar"
                        display={['flex', 'flex', 'flex', 'none']}
                    >
                        <CompetitionsTabBar
                            key={pageTabbarType}
                            items={competitions || []}
                            isVisible={isSticky}
                            setIsBurgerMenuOpen={setIsBurgerMenuOpen}
                        />
                    </NonFlexingContainer>
                )}
            </div>
            <NonFlexingContainer
                marginBottom={['spacing-6', 'spacing-6', 'spacing-9']}
                maxWidth={1040}
                width="100%"
                marginX="auto"
            >
                {navigation?.tag && <BreadCrumbs />}
                {!disableBillboard && firstBillboard
                    ? renderAdWithPlaceholder(firstBillboard)
                    : null}
                {isCompetitionsTabbar && navigation?.tag?.id !== tvTagId ? (
                    <NonFlexingContainer display={['none', 'none', 'none', 'flex']}>
                        {navigation?.tag ? (
                            <NonFlexingContainer
                                height={65}
                                paddingX="spacing-7"
                                marginTop="spacing-9"
                                backgroundColor="pearl"
                                centerVertical
                            >
                                <CardHeadline
                                    text={`Alles zu ${navigation.tag.title}`}
                                    cardHeadlineType={[
                                        'cardHeadlineS',
                                        'cardHeadlineM',
                                        'cardHeadlineM',
                                    ]}
                                />
                            </NonFlexingContainer>
                        ) : null}
                        {shouldShowCompetitionsTabbar && (
                            <CompetitionsTabBar key={pageTabbarType} items={competitions || []} />
                        )}
                    </NonFlexingContainer>
                ) : null}
            </NonFlexingContainer>
        </>
    )
}

export default Header
